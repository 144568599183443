import React from "react"
import navbarStyles from "../scss/navbar.module.scss";
import { useStaticQuery, graphql, Link } from "gatsby";

/**
 * The navigation bar of the page. We use the navigation pages
 * as described in ../gatsby-config.js to generate the buttons
 * correctly. To fetch that info, we had to use a GraphQL 
 * query.
 */
export default function NavigationBar({openOverlay}) {
  const data = useStaticQuery(graphql`
    query NavigationBarQuery {
      site {
        siteMetadata {
          title
          subtitle
          navPages
        }
      }
    }
  `)

  var { title, subtitle, navPages } = data.site.siteMetadata;
  return (
    <div className={navbarStyles.navbarWrap}>
      <div className="container">
        <div className={navbarStyles.navbar}>
          <div className={navbarStyles.side + ' ' + navbarStyles.leftSide}>
            <h1>{data.site.siteMetadata.title}</h1>
            <h2>{data.site.siteMetadata.subtitle}</h2>
          </div>
          <div className={navbarStyles.side + ' ' + navbarStyles.rightSide}>
            {navPages.map(item => <Link key={item[1]} to={item[1]} className={navbarStyles.button}>{item[0]}</Link>)}
            <a onClick={openOverlay} className={navbarStyles.action_button + " " + navbarStyles.button}>Your Order</a>
          </div>
        </div>
      </div>
    </div>
  );
}