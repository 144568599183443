import React from "react"
import { Helmet } from "react-helmet"
import NavigationBar from "./navbar";
import Footer from "./footer";
import OrderOverlay from "./OrderOverlay";
import "../scss/layout.scss";
import favicon from '../images/favicon.ico'
import { CookiesProvider } from 'react-cookie';
import 'bulma/css/bulma.css';



/**
 * A component that wraps around all pages onscreen. Adds the Helmet
 * elements, navigation bar, footer, and a body wrapper.
 * @param {string} options.title    a title passed in for the page
 * @param {jsx} options.children    all child elements
 */
export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      overlayShown: false,
      payment_res: null,
      notif_visible: false
    }

    /**
     * Simple listener paradigm for sending order information
     * from various points in the app.
     * @type {Object} orderProvider
     */
    this.orderProvider = {
      listener: () => {}
    }

    this.orderProvider["setListener"] = (callback) => {
      this.orderProvider.listener = callback;
    }

    this.orderProvider["push"] = (dataObj) => {
      if (this.orderProvider.listener)
        this.orderProvider.listener(dataObj);
    }

    // Check for URL params
    if (typeof window !== 'undefined') {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      this.state.payment_res = params.get('payment');
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ notif_visible: false }), 4000);
    setTimeout(() => {
      if (this.state.payment_res)
        this.setState({ notif_visible:true });
    }, 100);
    
    
  }

  render() {
    let { title, children, fetchOrderProvider } = this.props
    if (fetchOrderProvider) 
      fetchOrderProvider(this.orderProvider);
    return (
      <div id="app">
        <CookiesProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title ? `3D Tours | ${title}` : `3D Virtual Tours`}</title>
            <link rel="icon" href="/favicon.ico" />
            <link rel="canonical" href="http://mysite.com/example" />
          </Helmet>
          <NavigationBar openOverlay={ () => this.setState({ overlayShown: true }) }/>

          <div 
            style={{ 
              position: 'absolute', 
              top: this.state.notif_visible ? 50 : -100, 
              left: '50%',
              width: '250px',
              marginLeft: '-125px',
              zIndex: 5,
              transition: 'top 0.5s'
            }} 
            className={`notification ${this.state.payment_res == 'success' ? 'is-success' : 'is-danger'}`}>
            <button onClick={() => this.setState({ notif_visible: false })} className="delete"></button>
            {this.state.payment_res == 'success' ? 'Payment succeeded.' : 'Payment cancelled.'}
          </div>

          <OrderOverlay 
            shown={this.state.overlayShown} 
            setShown={b => this.setState({ overlayShown: b })} 
            orderProvider={this.orderProvider} />

          <div className="body">
            {children}
          </div>
          <Footer />
        </CookiesProvider>
      </div>
    )
  }
}