import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import orderOverlayStyles from "../scss/orderoverlay.scss";
import { loadStripe } from '@stripe/stripe-js';
import { dynamic_content_url } from '../config.json';

function Field({ id, title, placeholder, value, setValue, warning }) {
  return (
    <div className="field">
      {title && <label className="label">{title}<span className="asterisk">*</span></label>}
      <div className="control is-expanded">
        <input 
          className={`input ${warning ? 'is-danger' : 'removeOutline'}`} 
          onChange={e => setValue({ id: id, value: e.target.value })} 
          type="text" 
          placeholder={placeholder}
          value={value} />
      </div>
      {warning && <p className="help is-danger">{warning}</p>}
    </div>
  );
}

function MailChimpEmbed({ order, isCheckout, onClick, canSubmit }) {
  return (
    <div id="mc_embed_signup">
      <iframe name="iframe_a" style={{ display: 'none' }} />
      <form 
        action="https://3DToursNY.us10.list-manage.com/subscribe/post?u=bceddfaed8d41ce3496d54f2e&amp;id=53ad0d33c1"
        method="post" 
        id="mc-embedded-subscribe-form" 
        name="mc-embedded-subscribe-form" 
        className="validate" 
        target={isCheckout ? "iframe_a" : "_blank"} 
        noValidate>
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group" style={{ display: 'none' }}>
            <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
            </label>
            <input type="email" value={order.email} readOnly name="EMAIL" className="required email" id="mce-EMAIL" />
          </div>
          <div className="mc-field-group field">
            <label className="label" htmlFor="mce-FNAME">First Name </label>
            <input type="text" defaultValue="" name="FNAME" className="input" id="mce-FNAME" />
          </div>
          <div className="mc-field-group field">
            <label className="label" htmlFor="mce-LNAME">Last Name </label>
            <input type="text" defaultValue="" name="LNAME" className="input" id="mce-LNAME" />
          </div>
          <div className="mc-field-group size1of2 field" style={{ display: 'none' }}>
            <input type="text" name="PHONE" value={order.phone} readOnly className="required input" id="mce-PHONE" />
          </div>
          <div class="mc-field-group field" style={isCheckout ? { display: 'none' } : {}}>
            {!isCheckout && (
              <div className="control">
                <label className="label" htmlFor="mce-MMERGE3">Message</label>
                <textarea type="text" defaultValue="" name="MMERGE3" className="textarea" id="mce-MMERGE3" />
              </div>
            )}
            {isCheckout && <input type="text" value={"CHECKING OUT: " + JSON.stringify(order.items)} name="MMERGE3" id="mce-MMERGE3" />}
          </div>
          <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{ display:'none' }}></div>
            <div className="response" id="mce-success-response" style={{ display:'none' }}></div>
          </div>
          <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
            <input type="text" name="b_bceddfaed8d41ce3496d54f2e_53ad0d33c1" tabIndex="-1" defaultValue="" />
          </div>
          {!isCheckout && <p className="field">One or more of the items you'd like require us to provide a quote. We'll take your information and get back to you as soon as possible.</p>}
          <div className="clear">
            <input 
              disabled={order.items.length <= 0 || !canSubmit} 
              type="submit" 
              onClick={onClick} 
              defaultValue="Subscribe" 
              name="subscribe" 
              id="mc-embedded-subscribe" 
              className={`button${isCheckout ? ' is-primary':' is-info'}` } />
          </div>
        </div>
      </form>
    </div>
  );
}

export default function OrderOverlay({ shown, setShown, orderProvider }) {
  const [ cookies, setCookie] = useCookies(['order']);
  const [ order, setOrder ] = useState({
    email: '',
    phone: '',
    items: []
  });
  const [ emailEmpty, setEmailEmpty ] = useState(false);
  const [ phoneEmpty, setPhoneEmpty ] = useState(false);
  const [ stripePromise, setStripe ] = useState(null);
  const [ getQuote, setGetQuote ] = useState(false);

  useEffect(() => {
    let b = false;
    for (let i in order.items) {
      let item = order.items[i];
      if (!item.price || !item.stripe_id) {
        b = true;
        break;
      }
    }
    setGetQuote(b);
  }, [order]);
  

  useEffect(() => {
    if (cookies.order) {
      setOrder(cookies.order)
      setTimeout(100, setOrder(cookies.order));
    }
    fetch(dynamic_content_url)
      .then(response => response.json())
      .then(data => {
        // Make sure to call `loadStripe` outside of a component’s render to avoid
        // recreating the `Stripe` object on every render.
        setStripe(loadStripe(data.stripe_api_key));
      });
  }, []);

  function fetchLineItems() {
    return order.items.map(el => { 
      return { price: el.stripe_id, quantity: el.quantity }
    });
  }

  orderProvider.setListener(orderSelection => { 
    setShown(true);
    let newOrder = { ...order }
    newOrder.items.push(orderSelection)
    setOrder(newOrder)
  });

  const modOrder = (cb) => setOrder(cb({ ...order }));

  const setValue = ({ id, value }) => modOrder(newOrder => {
    newOrder[id] = value;
    return newOrder;
  });

  const removeItem = (el) => modOrder(newOrder => {
    newOrder.items = newOrder.items.filter(val => val !== el);
    return newOrder;
  });

  const incrementQuantity = (index) => modOrder(newOrder => {
    newOrder.items[index].quantity++;
    return newOrder;
  });

  const decrementQuantity = (index) => modOrder(newOrder => {
    if (newOrder.items[index].quantity > 1) newOrder.items[index].quantity--;
    if (newOrder.items[index].quantity == 1) removeItem(newOrder.items[index]);
    return newOrder;
  });

  let emailStatus = order.email == undefined || order.email == '';
  let phoneStatus = order.phone == undefined || order.phone == '';
  let canSubmit = !phoneStatus && !emailStatus;

  async function handleCheckout(event) {
    if (stripePromise == null) return;
    setEmailEmpty(emailStatus);
    setPhoneEmpty(phoneStatus);
    if (!emailStatus && !phoneStatus) {
      setEmailEmpty(false);
      const url = typeof window !== 'undefined' ? window.location.href : '';
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        lineItems: fetchLineItems(),
        mode: 'payment',
        successUrl: url.split('?')[0] + '?payment=success',
        cancelUrl: url.split('?')[0] + '?payment=cancel',
        customerEmail: order.email
      });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    }
  }

  useEffect(function () {
    setCookie('order', order, { path: '/' });
  }, [order]);

  let cl = shown ? 'open' : '';
  return (
    <div className={`shadeBackground ${cl}`}>
      <div className={`sideWindow ${cl}`}>
        <div className="sidebarHeader">
          <span onClick={() => setShown(false)} className="close">×</span>
          <span className="divider" />
          <span className="checkoutTitle">
            Your Order
          </span>
        </div>
        {order.items.length > 0 && <div className="section" style={{ paddingTop: '2rem' }}> 
          <div className="field">
            <label className="label">Summary</label>
          </div>
          <table className="table is-fullwidth is-striped is-hoverable">
            <tbody>
              {order.items.map((el, i) => (
                <tr key={i}>
                  <td style={{ fontSize: '1.3rem', userSelect: 'none' }}>
                    <a className="noSelect" onClick={() => incrementQuantity(i)}>+</a>
                    <i>&nbsp;{el.quantity}x&nbsp;</i>
                    <a className="noSelect" onClick={() => decrementQuantity(i)}>-</a>
                  </td>
                  <td>{el.title}{el.size && <br />}{el.size && <span className="tag">{el.size}</span>}</td>
                  <td></td>
                  <td>{el.addon && <span className="tag is-info">Addon</span>}</td>
                  <td><strong>{el.price}</strong></td>
                  <td>
                    <button className="button is-small is-light is-danger" onClick={() => removeItem(el)}>
                      <i className="fas fa-times" />
                    </button>
                  </td>
                </tr>
              ))} 
            </tbody>
          </table>
          <Field 
            id="email"
            setValue={setValue}
            value={order.email}
            placeholder=""
            warning={emailEmpty ? "Required" : ""}
            title="Email" />
          <Field 
            id="phone"
            setValue={setValue}
            value={order.phone}
            placeholder=""
            warning={phoneEmpty ? "Required" : ""}
            title="Phone" />
          <MailChimpEmbed isCheckout={!getQuote} onClick={handleCheckout} order={order} canSubmit={canSubmit} />
        </div>}
        {order.items.length <= 0 && (<div className="section" style={{ paddingTop: '2rem' }}><p className="subtitle">Nothing here yet.</p></div>)}
      </div>
    </div>
  )
}
