import React from "react"
import footerStyles from "../scss/footer.module.scss";
import { dynamic_content_url } from '../config.json';

// const socialIcons = [
//   ["facebook-square", "David Kiszka 3D Tours", "#"],
//   ["twitter", "@davidkiszka", "#"],
//   ["instagram", "@dkiszka", "#"],
// ]

function getIcons(socialIcons) {
  return socialIcons.map(item => (
    <a key={item[1]} className={footerStyles.link} href={item[2]}>
      <span className={footerStyles.socialField}>
        <i className={`fab fa-${item[0]}`} style={{ width: '24px' }}></i>
        <span className={footerStyles.socialText}>
          {item[1]}
        </span>
      </span>
    </a>
    )
  );
}

const TitleColumn = ({ className }) => (
  <div className={className + " column"}>
    <div className={footerStyles.titlePosition}>
      <span className={footerStyles.title}>
        David Kiszka
      </span><br />
      <span className={footerStyles.subtitle}>
        3d virtual home tours
      </span>
    </div>
  </div>
);

/**
 * The footer of every page. Imported only by layout.js. We use
 * a SCSS component module to generate some footer-specific styling.
 */
export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: { social: [], contact: {}}
    }
  }
  componentDidMount() {
    fetch(dynamic_content_url)
      .then(response => response.json())
      .then(data => this.setState({ data: data.footer }));
  }
  render() {
    let contact_arr = []
    let { contact, social } = this.state.data;
    for (const [key, value] of Object.entries(contact)) {
      contact_arr.push(`${key}: ${value}`);
    }
    return (
      <div className={footerStyles.footer}>
        <div className="container">
          <div className="columns">
            <div className="column">
              <span className={footerStyles.sectionHead}>Contact Info</span><br />
              <span className={footerStyles.divider} />
              {contact_arr.map((obj, i) => (<p key={i}>{obj}</p>))}
            </div>
            <TitleColumn className={footerStyles.middleTitle}/>
            <div className="column">
              <span className={footerStyles.sectionHead}>Social Media</span><br />
              <span className={footerStyles.divider} />
              {getIcons(social)}
            </div>
            <TitleColumn className={footerStyles.bottomTitle}/>
          </div>
        </div>
      </div>
    )
  }
}